import axios from "axios";

// ** Initial State
const initialState = {
  suggestions: [],
  bookmarks: [],
  query: "",
};

// ** Action Types
const HANDLE_SEARCH_QUERY = "HANDLE_SEARCH_QUERY";
const GET_BOOKMARKS = "GET_BOOKMARKS";
const UPDATE_BOOKMARKED = "UPDATE_BOOKMARKED";

// ** Reducer
const navbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_SEARCH_QUERY:
      return { ...state, query: action.val };
    case GET_BOOKMARKS:
      return {
        ...state,
        suggestions: action.data,
        bookmarks: action.bookmarks,
      };
    case UPDATE_BOOKMARKED:
      let objectToUpdate;

      // ** find & update object
      state.suggestions.forEach((item) => {
        if (item.id === action.id) {
          item.isBookmarked = !item.isBookmarked;
          objectToUpdate = item;
        }
      });

      // ** Get index to add or remove bookmark from array
      const bookmarkIndex = state.bookmarks.findIndex(
        (x) => x.id === action.id
      );

      if (bookmarkIndex === -1) {
        state.bookmarks.push(objectToUpdate);
      } else {
        state.bookmarks.splice(bookmarkIndex, 1);
      }

      return { ...state };
    default:
      return state;
  }
};

// ** Get Bookmarks Array from @fakeDB
export const getBookmarks = () => {
  return (dispatch) => {
    return axios.get("/api/bookmarks/data").then((response) => {
      dispatch({
        type: GET_BOOKMARKS,
        data: response.data.suggestions,
        bookmarks: response.data.bookmarks,
      });
    });
  };
};

// ** Update & Get Updated Bookmarks Array
export const updateBookmarked = (id) => {
  return (dispatch) => {
    return axios.post("/api/bookmarks/update", { id }).then(() => {
      dispatch({ type: UPDATE_BOOKMARKED, id });
    });
  };
};

// ** Handle Bookmarks & Main Search Queries
export const handleSearchQuery = (val) => (dispatch) =>
  dispatch({ type: HANDLE_SEARCH_QUERY, val });

export default navbarReducer;

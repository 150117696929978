const initialState = {
  dashboard: {},
  products: [],
  unmappedProducts: [],
  providers: [],
  users: [],
  brewers: [],
  providerDetail: {},
  productDetail: {},
  unMappedProductDetail: {},
  userDetail: {},
  brewerDetail: {},
  params: {},
  totalProducts: 0,
  totalUnmappedProducts: 0,
  totalProviders: 0,
  totalBrewers: 0,
  productsSelcted: [],
  unmappedProductsSelected: [],
  brewersSelcted: [],
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADMIN_GET_PRODUCTS":
      return {
        ...state,
        products: action.data.products,
        params: action.params,
        totalProducts: action.data.total,
      };
    case "ADMIN_GET_UNMAPPED_PRODUCTS":
      return {
        ...state,
        unmappedProducts: action.data.products,
        params: action.params,
        totalUnmappedProducts: action.data.total,
      };
    case "GET_PROVIDERS":
      return {
        ...state,
        providers: action.data.providers,
        params: action.params,
        totalProviders: action.data.total,
      };
    case "ADMIN_GET_PRODUCT":
      return { ...state, productDetail: action.data };
    case "ADMIN_GET_UNMAPPED_PRODUCT":
      return { ...state, unMappedProductDetail: action.data };
    case "GET_PROVIDER":
      return { ...state, providerDetail: action.data };
    case "GET_USERS":
      return {
        ...state,
        users: action.data.users,
        params: action.params,
        totalUsers: action.data.total,
      };
    case "GET_USER":
      return { ...state, userDetail: action.data };
    case "GET_BREWERS":
      return {
        ...state,
        brewers: action.data.brewers,
        params: action.params,
        totalBrewers: action.data.total,
      };
    case "GET_BREWER":
      return { ...state, brewerDetail: action.data };
    case "GET_DASHBOARD":
      return { ...state, dashboard: action.data };
    case "UPDATE_BREWER_SELECTION":
      return { ...state, brewersSelcted: action.data };
    case "UPDATE_PRODUCT_SELECTION":
      return { ...state, productsSelcted: action.data };
    case "UPDATE_PRODUCT_UNMAPPED_SELECTION":
      return { ...state, unmappedProductsSelected: action.data };
    default:
      return state;
  }
};

// ** GET Products
export const getProducts = (data, params) => {
  return (dispatch) => {
    return dispatch({
      type: "ADMIN_GET_PRODUCTS",
      data: {
        products: data.products,
        total: data.total.length !== 0 ? data.total.count : 0,
      },
      params,
    });
  };
};

// ** GET UnmappedProducts
export const getUnmappedProducts = (data, params) => {
  return (dispatch) => {
    return dispatch({
      type: "ADMIN_GET_UNMAPPED_PRODUCTS",
      data: { products: data.unmapped_products, total: data.count.total },
      params,
    });
  };
};

// ** GET Brewers
export const getBrewers = (data, params) => {
  console.log(data);
  return (dispatch) => {
    return dispatch({
      type: "GET_BREWERS",
      data: {
        brewers: data.brewers,
        total: data.total.length !== 0 ? data.total[0].count : 0,
      },
      params,
    });
  };
};

// ** GET Brewer
export const getBrewer = (id, data) => {
  return (dispatch) => {
    return dispatch({ type: "GET_BREWER", data });
  };
};

// ** GET Providers
export const getProviders = (data, params) => {
  return (dispatch) => {
    return dispatch({
      type: "GET_PROVIDERS",
      data: {
        providers: data.providers,
        total: data.total.length !== 0 ? data.total[0].count : 0,
      },
      params,
    });
  };
};

// ** GET Provider
export const getProvider = (id, data) => {
  return (dispatch) => {
    return dispatch({ type: "GET_PROVIDER", data });
  };
};

// ** GET Single Product
export const getProduct = (id, data) => {
  return (dispatch) => {
    return dispatch({ type: "ADMIN_GET_PRODUCT", data });
  };
};

// ** GET Single Product
export const getUnmappedProduct = (id, data) => {
  return (dispatch) => {
    return dispatch({ type: "ADMIN_GET_UNMAPPED_PRODUCT", data });
  };
};

export const updateBrewerSelection = (data) => {
  return (dispatch) => {
    return dispatch({ type: "UPDATE_BREWER_SELECTION", data });
  };
};

export const updateProductselection = (data) => {
  return (dispatch) => {
    return dispatch({ type: "UPDATE_PRODUCT_SELECTION", data });
  };
};

export const updateProductselectionUnmapped = (data) => {
  return (dispatch) => {
    return dispatch({ type: "UPDATE_PRODUCT_UNMAPPED_SELECTION", data });
  };
};

export default adminReducer;

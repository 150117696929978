// ** React Imports
import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";

// ** Toast & ThemeColors Context
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "./utility/context/ThemeColors";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

// ** Initial ability & context
import ability from "./configs/acl/ability";
import { AbilityContext } from "./utility/context/Can";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";
import { IntlProviderWrapper } from "./utility/context/Internationalization";

// ** Apollo GraphQL imports
import { HttpLink } from "@apollo/client/link/http";
import { setContext } from "@apollo/client/link/context";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  from,
} from "@apollo/client";

// ** Various imports
const GRAPHQL_URL = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/graphql`;

const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem("idToken")}`,
    },
  };
});

const additiveLink = from([
  authLink,
  new HttpLink({
    uri: GRAPHQL_URL,
  }),
]);

const client = new ApolloClient({
  link: additiveLink,
  cache: new InMemoryCache(),
});

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <AbilityContext.Provider value={ability}>
        <ThemeContext>
          <IntlProviderWrapper>
            <ApolloProvider client={client}>
              <LazyApp />
              <ToastContainer newestOnTop />
            </ApolloProvider>
          </IntlProviderWrapper>
        </ThemeContext>
      </AbilityContext.Provider>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// ** Action Types

import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { auth, idTokenKey, userDataKey } from "../../../auth/firebaseConfig";

// **  Initial State
const initialState = {
  userData: {},
  isAuthenticated: false,
  loading: false,
  error: null,
};

// ** Action Types
const LOGIN_REQUEST = "LOGIN_REQUEST";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILURE = "LOGIN_FAILURE";
const LOGOUT = "LOGOUT";

// ** Reducer
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, loading: true, error: null };
    case LOGIN_SUCCESS:
      return {
        ...state,
        userData: action.data,
        isAuthenticated: true,
        loading: false,
      };
    case LOGIN_FAILURE:
      return { ...state, error: action.error, loading: false };
    case LOGOUT:
      return { ...state, userData: {}, isAuthenticated: false };
    default:
      return state;
  }
};

// ** Action Creators
export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = (data) => ({ type: LOGIN_SUCCESS, data });
export const loginFailure = (error) => ({ type: LOGIN_FAILURE, error });
export const logout = () => ({ type: LOGOUT });

// ** Thunks
export const handleLogin = (email, password) => {
  return async (dispatch) => {
    dispatch(loginRequest());
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const token = await userCredential.user.getIdToken();
      const userData = { email: userCredential.user.email, token };
      localStorage.setItem(userDataKey, JSON.stringify(userData));
      dispatch(loginSuccess(userData));
    } catch (error) {
      dispatch(loginFailure(error.message));
    }
  };
};

export const handleLogout = () => {
  return (dispatch) => {
    dispatch(logout());
    localStorage.removeItem("userData");
  };
};

export const checkUserLoggedIn = () => {
  return (dispatch) => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        const userData = { email: user.email, token };
        localStorage.setItem(idTokenKey, token);
        localStorage.setItem(userDataKey, JSON.stringify(userData));
        dispatch(loginSuccess(userData));
      } else {
        dispatch(logout());
      }
    });
  };
};

export default authReducer;

// 37zGnWulgJXxAVg0smMJa1INA_mVa1Xpxw
